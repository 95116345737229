import { Link, useSearchParams } from "react-router-dom";
import {
  AutocompleteInput,
  Create,
  DateInput,
  Edit,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreatePath,
  useNotify,
  useRedirect,
} from "react-admin";
import {
  Button,
  Card,
  CardHeader,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { countries } from "../resources/countries";

const FormToolbar = (props) => (
  <Toolbar sx={{ justifyContent: "right" }}>
    <SaveButton
      color="black"
      sx={{ textTransform: "none" }}
      icon={<SaveOutlinedIcon />}
    />
  </Toolbar>
);

const BackButton = (props) => {
  const createPath = useCreatePath();
  return (
    <Button
      component={Link}
      to={createPath({ type: "list", resource: "users" })}
      color="yellow"
      variant="contained"
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        fontWeight: "bold",
        textTransform: "none",
      }}
      startIcon={<ArrowBackIcon />}
    >
      Back
    </Button>
  );
};

const diagnoses = [
  { id: "Autism Spectrum Disorder", name: "Autism Spectrum Disorder" },
  { id: "Speech Apraxia", name: "Speech Apraxia" },
  { id: "Learning Disability", name: "Learning Disability" },
  { id: "Down Syndrome", name: "Down Syndrome" },
  { id: "Other", name: "Other" },
];

const ChildForm = (props) => {
  return (
    <SimpleForm sx={{ px: 3 }} toolbar={<FormToolbar />} {...props}>
      <TextInput source="first_name" fullWidth />
      <TextInput source="last_name" fullWidth />
      <SelectInput
        source="gender"
        fullWidth
        choices={[
          { id: "M", name: "Male" },
          { id: "F", name: "Female" },
          { id: "O", name: "Other" },
          { id: "N", name: "Prefer not to say" },
        ]}
        validate={required()}
      />
      <DateInput source="birthdate" fullWidth />
      <AutocompleteInput
        source="country"
        fullWidth
        choices={countries.map((c) => ({ id: c.label, name: c.label }))}
      />
      <AutocompleteInput
        source="diagnosis"
        fullWidth
        choices={diagnoses}
      />
    </SimpleForm>
  );
};

export const ChildCreate = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();
  const [searchParams] = useSearchParams();
  const parent = searchParams.get("parent");

  const onSuccess = (data) => {
    notify("Child created.");
    redirect("list", "users");
  };

  const onError = (error) => {
    notify("Could not create child.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton />
      <Container maxWidth="sm">
        <Create
          title="New Child"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Child"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <ChildForm defaultValues={{ parent }} />
          </Card>
        </Create>
      </Container>
    </Container>
  );
};

export const ChildEdit = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify("Child updated.");
    redirect("list", "users");
  };

  const onError = (error) => {
    notify("Could not update child.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton />
      <Container maxWidth="sm">
        <Edit
          title="Edit Child"
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Child"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <ChildForm />
          </Card>
        </Edit>
      </Container>
    </Container>
  );
};
